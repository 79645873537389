import React from "react";
import img1 from "./cutoff.png";
import img2 from "./cutoff.png";
import { Image, Box, Button } from "@chakra-ui/react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const Banner = () => {
  const handlePayment = async () => {
    // Predefined values
    // Replace with the actual URL

    // Create the payment data
    const paymentData = {
      merchant_id: "3080295",
      order_id: uuidv4(),
      currency: "INR",
      amount: 799,
      redirect_url: "https://asianlinkacademy.com/",
      cancel_url: "https://admissionpossibility.com/",
      language: "EN",
    };

    console.log(paymentData);

    window.location.href = "https://api.mbbsdunia.com/pay";
    // window.location.href = "http://localhost:3100/pay";
  };
  // Function to generate a random order id

  return (
    <div>
      <Box
        width={"100%"}
        display={["none", "none", "block", "block"]}
        height={"50vh"}
        position={"relative"}
      >
        <Image src={img1} width={"100%"} height={"100%"}></Image>

        <Button
          position={"absolute"}
          onClick={handlePayment}
          top={"70%"}
          left={"40%"}
          fontWeight={"200"}
          py={6}
          backgroundColor={"#F4903A"}
          width={"14%"}
          border={"5px solid white"}
          borderRadius={"full"}
          _hover={{ background: "rgb(28,55,91)",color:"white" }}
        >
          {" "}
          Get Cutoffs Now
        </Button>
      </Box>

      <Box
        width={"100vw"}
        display={["block", "block", "none", "none"]}
        height={"30vh"}
        position={"relative"}
      >
        <Image src={img2} width={"100%"} height={"100%"}></Image>

        <Button
          position={"absolute"}
          onClick={handlePayment}
          top={"64%"}
          left={"35%"}
          fontWeight={"200"}
          padding={"5px 15px"}
          backgroundColor={"#F4903A"}
          width={"24%"}
          fontSize={8}
          border={"5px solid white"}
          borderRadius={"full"}
          _hover={{ background: "rgb(28,55,91)",color:"white" }}
        >
          {" "}
          Get Cutoffs Now
        </Button>
      </Box>
    </div>
  );
};

export default Banner;
