import React, { useEffect } from "react";
import img1 from "./about.png";
import {
  Box,
  background,
  position,
  button,
  Image,
  Stack,
} from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";
import "./About.css";
import {
  HStack,
  Flex,
  Icon,
  Divider,
  Button,
  Heading,
  VStack,
} from "@chakra-ui/react";
import WhatsApp from "../components/Whatsapp/whatsapp";
const About = () => {
  return (
    <Box>
      <WhatsApp />
      <Box
        // border={"1px solid red"}
        className="container4"
        width="100%"
        // height={{ base: "180vh", md: "100vh", lg: "100vh", xl: "100vh" }}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        py={5}
      >
        <h2
          style={{
            fontSize: "2rem",
            marginTop: "10px",
            color: "#1C375B",
            fontWeight: "bold",
          }}
        >
          About Us
        </h2>

        <Stack
          // height={{ base: "160vh", md: "90vh", lg: "90vh", xl: "160vh" }}
          width={{base:"90%",lg:"80%"}}
          margin={"auto"}
          flexDirection={{ base: "column", lg: "row" }}
          gap={{ base: 20, lg: 10 }}
        >
          <Box
            width={{ base: "90%", sm: "90%", md: "80%", xl: "50%" }}
            margin="auto"
            // height={{ base: "0vh", md: "auto" }}
          >
            <Image
              src={img1}
              w={{ base: "90%", md: "100%" }}
              margin={"auto"}
              h="auto"
            />
          </Box>

          <Box
            width={{ base: "90%", sm: "90%", md: "80%", xl: "50%" }}
            margin={"auto"}
          >
            <Box
              fontSize={{ base: "0.8rem", lg: "1rem" }}
              style={{
                color: "rgba(0,0,0,0.6)",
              }}
              textAlign={"start"}
            >
              Welcome to MBBS Dunia, where your journey to becoming a successful
              medical professional begins. At MBBS Dunia, we understand that
              choosing the right MBBS college is a pivotal decision in your
              academic and professional life. Our mission is to empower aspiring
              doctors like you with accurate, comprehensive, and up-to-date
              information about MBBS colleges worldwide. We are dedicated to
              simplifying the college selection process and helping you make
              informed choices that will shape your future.
              <Box marginTop={"3vh"}>
                <Flex alignItems={"center"} gap={2}>
                  <Icon as={StarIcon} w={25} h={25} color="#023B7D" />
                  <Text
                    marginLeft={"10px"}
                    fontSize={{ base: "0.7rem", lg: "1rem" }}
                  >
                    Educational Excellence : MBBSDunia is dedicated to
                    delivering high-quality education through expert faculty and
                    cutting-edge methods. We continuously update our curriculum
                    to ensure students receive the best preparation for their
                    careers.
                  </Text>
                </Flex>
              </Box>
              <Box marginTop={"3vh"}>
                <Flex alignItems={"center"} gap={2}>
                  <Icon as={StarIcon} w={25} h={25} color="#023B7D" />
                  <Text
                    marginLeft={"10px"}
                    fontSize={{ base: "0.7rem", lg: "1rem" }}
                  >
                    User-Centric Approach : Our focus is on meeting students'
                    individual needs and goals. We create a supportive and
                    personalized learning environment that helps students reach
                    their full potential.
                  </Text>
                </Flex>
              </Box>
              <Box marginTop={"3vh"}>
                <Flex alignItems={"center"} gap={2}>
                  <Icon as={StarIcon} w={25} h={25} color="#023B7D" />
                  <Text
                    marginLeft={"10px"}
                    fontSize={{ base: "0.7rem", lg: "1rem" }}
                  >
                    Continuous Improvement : We are committed to continually
                    enhancing our programs and services through regular
                    evaluations and feedback. This ensures that we provide an
                    exceptional educational experience.
                  </Text>
                </Flex>
              </Box>
              <Box marginTop={"3vh"}>
                <Flex alignItems={"center"} gap={2}>
                  <Icon as={StarIcon} w={25} h={25} color="#023B7D" />
                  <Text
                    marginLeft={"10px"}
                    fontSize={{ base: "0.7rem", lg: "1rem" }}
                  >
                    Community Support : We build a vibrant network where
                    students, alumni, and faculty support and enrich each other.
                    This community fosters collaboration and offers valuable
                    resources and mentorship.
                  </Text>
                </Flex>
              </Box>
              <Box marginTop={"3vh"}>
                <Flex alignItems={"center"} gap={2}>
                  <Icon as={StarIcon} w={25} h={25} color="#023B7D" />
                  <Text
                    marginLeft={"10px"}
                    fontSize={{ base: "0.7rem", lg: "1rem" }}
                  >
                    Feedback Integration : We value feedback from our community
                    and use it to refine our programs and services, ensuring
                    they remain relevant and effective.
                  </Text>
                </Flex>
              </Box>
            </Box>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
export default About;