import React from "react";
import { Box, Button, Image } from "@chakra-ui/react";
import img1 from "../Banner/cutoff.png";
import img2 from "../Banner/cutoff.png";
import { v4 as uuidv4 } from "uuid";

const Cutoff = () => {
  const handlePayment = async () => {
    // Predefined values
    // Replace with the actual URL

    // Create the payment data
    const paymentData = {
      merchant_id: "3080295",
      order_id: uuidv4(),
      currency: "INR",
      amount: 799,
      redirect_url: "https://asianlinkacademy.com/",
      cancel_url: "https://admissionpossibility.com/",
      language: "EN",
    };
    console.log(paymentData);
    window.location.href = "https://api.mbbsdunia.com/pay";
    // window.location.href = "http://localhost:3100/pay";
  };

  return (
    <div>
      <Box
        // width={"100vw"}
        // height={["65vh", "60vh", "80vh", "80vh"]}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box
          display={["none", "none", "block", "block"]}
          // width={["80vw", "90vw", "100vw", "100vw"]}
          width={"100%"}
          // height={["50vh"]}
          position={"relative"}
        >
          <Image src={img1} width={"100%"} height={"100%"}></Image>

          <Button
            position={"absolute"}
            onClick={handlePayment}
            top={"66%"}
            left={"37%"}
            fontWeight={700}
            // padding={"5px 15px"}
            py={6}
            backgroundColor={"#F4903A"}
            width={"20%"}
            borderRadius={"full"}
            border={"4px solid white"}
            _hover={{ bg: "rgb(28,55,91)", color: "white" }}
          >
            {" "}
            Get Cutoffs Now
          </Button>
        </Box>

        <Box
          // width={"100vw"}
          display={["block", "block", "none", "none"]}
          height={"24vh"}
          position={"relative"}
        >
          <Image src={img2} width={"100%"} height={"100%"}></Image>

          <Button
            position={"absolute"}
            onClick={handlePayment}
            top={"64%"}
            left={"35%"}
            fontWeight={700}
            fontSize={"0.5rem"}
            backgroundColor={"#F4903A"}
            borderRadius={"full"}
            border={"4px solid white"}
            width={"24%"}
            _hover={{ bg: "rgb(28,55,91)", color: "white" }}
          >
            {" "}
            Get Cutoffs Now
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default Cutoff;
