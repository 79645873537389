import React, { useState } from "react";
import { Box, FormErrorMessage, useToast } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import {
  Flex,
  Button,
  VStack,
  FormControl,
  Input,
  Textarea,
  Image,
} from "@chakra-ui/react";
import img1 from "../Contact/contact1.png";
import img2 from "../Contact/Call.png";
import img3 from "../Contact/Message.png";
import img4 from "../Contact/Globe.png";
import axios from "axios";
import WhatsApp from "../components/Whatsapp/whatsapp";

const Contact = () => {
  const toast = useToast();
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    mobile: "",
    description: "",
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setContactData((prev) => ({ ...prev, [name]: value }));
  };

  const handleContactFormSubmit = async (e) => {
    e.preventDefault();
    console.log("contactData:", contactData);
    try {
      const responce = await axios.post(
        `https://api.mbbsdunia.com/api/contact`,
        contactData
      );
      if (responce.status === 200) {
        toast({
          title: `Thank you for contacting us!`,
          status: "success",
          isClosable: true,
        });
        setContactData({
          name: "",
          email: "",
          mobile: "",
          description: "",
        });
      }
      console.log("responce:", responce);
    } catch (error) {
      console.log("error:", error);
    }
  };

  return (
    <div style={{ marginTop: "20px", marginBottom: "20px" }} >
      <WhatsApp />
      <form onSubmit={handleContactFormSubmit}>
        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          width={"80%"}
          flexDirection={{
            base: "column",
            sm: "column",
            md: "column",
            lg: "row",
            xl: "row",
          }}
          margin={"auto"}
          mt={6}
          bg={"#ECECEC"}
          py={10}
          px={4}
          borderRadius={"xl"}
        >
          <Image
            src={img1}
            width={{ base: "100%", sm: "80%", md: "80%", xl: "44%" }}
            h={"54vh"}
            margin={"auto"}
          ></Image>

          <VStack
            width={{ base: "100%", xl: "50%" }}
            margin={"auto"}
            bg={"#1C375B"}
            borderRadius={"xl"}
            py={14}
            px={10}
          >
            <FormControl isRequired mb={3}>
              <Input
              fontSize={{base:"0.8rem",md:"1rem"}}
                onChange={handleInput}
                name="name"
                value={contactData.name}
                placeholder="Full name"
                border={"1px solid #0D3F64"}
                background={"#FFFFFF"}
                height={14}
                color={"#0D3F64"}
                _placeholder={{ color: "#0D3F64" }}
              />
            </FormControl>

            <FormControl isRequired mb={3}>
              <Input
              fontSize={{base:"0.8rem",md:"1rem"}}

                type="email"
                placeholder="Email"
                name="email"
                onChange={handleInput}
                value={contactData.email}
                required
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" // Email
                border={"1px solid #0D3F64"}
                background={"#FFFFFF"}
                height={14}
                color={"#0D3F64"}
                _placeholder={{ color: "#0D3F64" }}
              />
              <FormErrorMessage>Email address is not valid</FormErrorMessage>
            </FormControl>
            <FormControl isRequired mb={3}>
              <Input
              fontSize={{base:"0.8rem",md:"1rem"}}

                type="tel"
                placeholder="Enter 10 Digit Number"
                name="mobile"
                onChange={handleInput}
                value={contactData.mobile}
                pattern="[0-9]{10}"
                border={"1px solid #0D3F64"}
                background={"#FFFFFF"}
                height={14}
                color={"#0D3F64"}
                _placeholder={{ color: "#0D3F64" }}
                required
              />
              <FormErrorMessage>
                Please enter a valid phone number (10 digits only).
              </FormErrorMessage>
            </FormControl>

            <FormControl isRequired mb={3}>
              <Textarea
              fontSize={{base:"0.8rem",md:"1rem"}}

                onChange={handleInput}
                name="description"
                value={contactData.description}
                placeholder="Enter description"
                border={"1px solid #0D3F64"}
                background={"#FFFFFF"}
                height={14}
                color={"#0D3F64"}
                _placeholder={{ color: "#0D3F64" }}
              ></Textarea>
            </FormControl>

            <Flex width={"100%"} justifyContent={"end"}>
              <Button
                type="submit"
                color={"white"}
                fontWeight={"400"}
                border="2px solid white"

                backgroundColor={"#1C375B"}
                padding={"5px 40px"}
                _hover={{
                  color: "black",
                  border: "2px solid #1C375B",
                  backgroundColor: "white",
                }}
              >
                Submit
              </Button>
            </Flex>
          </VStack>
        </Flex>
      </form>
      <Box
        border={"1px solid white"}
        display="flex"
        flexDirection={["column", "column", "column","row", "row"]}
        justifyContent="space-evenly"
        alignItems="center"
        py={14}
        bg={"#ECECEC"}
        gap={{ base: 10, lg: 4 }}
        width={"80%"}
        margin={"80px auto"}
        borderRadius={"10px"}
      >
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          gap={4}
          width={{ base: "100%", lg: "20%" }}
        >
          <Image src={img2} />
          <Text
            fontWeight={800}
            color="#0E3F63"
            fontSize={{ base: "1rem", lg: "1rem",xl:"1.2rem" }}
          >
            +91 -98735604704
          </Text>
        </Flex>

        <Box
          as="hr"
          display={["none", "none", "none", "block"]}
          width="2px"
          height="100px"
          backgroundColor="#0E3F63"
          border="none"
        />
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          gap={4}
          width={{ base: "100%", lg: "20%" }}
        >
          <Image src={img3} />
          <Text
            fontWeight={800}
            color="#0E3F63"
            fontSize={{ base: "1rem", lg: "1rem",xl:"1.2rem" }}
          >
            info@mbbsdunia.com
          </Text>
        </Flex>

        <Box
          as="hr"
          display={["none", "none", "none", "block"]}
          width="2px"
          height="100px"
          backgroundColor="#0E3F63"
          border="none"
        />
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          gap={4}
          width={{ base: "100%", lg: "20%" }}
        >
          <Image src={img4} />
          <Text
            fontWeight={800}
            color="#0E3F63"
            fontSize={{ base: "1rem", lg: "1rem",xl:"1.2rem" }}
          >
            www.mbbsdunia.com
          </Text>
        </Flex>
      </Box>
    </div>
  );
};

export default Contact;