import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // Import useParams
import { StickyContainer, Sticky } from "react-sticky";
import { FaGlobe } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import img1 from "./facts-icon2.png";
import img2 from "./facts-icon3.png";
import img3 from "./facts-icon4.png";
import img4 from "./facts-icon7.png";

import {
  Container,
  Grid,
  Box,
  Text,
  Icon,
  HStack,
  Stack,
  Flex,
  CircularProgress,
  VStack,
  Img,
  Button,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from "@chakra-ui/react";
import { FaMapMarkerAlt } from "react-icons/fa";
import WhatsApp from "../Whatsapp/whatsapp";

function Collegepage() {
  const { id } = useParams();

  const [collegeData, setCollegeData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCollegeData = async () => {
      try {
        const response = await fetch(
          `https://api.mbbsdunia.com/api/universities/${id}`
        );
        if (response.status === 200) {
          const data = await response.json();
          setLoading(false);
          setCollegeData(data);
        } else {
          console.error("Error fetching college data");
        }
      } catch (error) {
        console.error("Error fetching college data:", error);
      }
    };

    fetchCollegeData();
  }, [id]);

  if (!collegeData) {
    return (
      <div>
        <Box
          width={"100vw"}
          height={"100vh"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Text>Loading Colleges....</Text>
          <CircularProgress isIndeterminate color="green.300" />
        </Box>
      </div>
    );
  }

  const tableStyle = {
    borderCollapse: "collapse",
    marginTop: "5vh",
    width: "100%",
    border: "none",
  };

  const cellStyle = {
    border: "1px solid #000",
    padding: "8px",
    textAlign: "center",
    border: "none",
    fontsize: "0.5rem",
  };

  const boldCellStyle = {
    ...cellStyle,
    color: "#014BEC",
    fontWeight: "bold",
    border: "none",
    fontsize: "0.8rem",
  };

  const lightGreyCellStyle = {
    ...cellStyle,
    backgroundColor: "#d3d3d3",
    border: "none",
  };

  const separatorCellStyle = {
    ...cellStyle,
    borderBottom: "1px solid #000",
    border: "none",
  };

  const bodyStyle = {
    fontFamily: "'Rubik', sans-serif",
    // Add other CSS styles as needed
  };

  // Apply the style to the body element
  document.body.style = Object.assign({}, document.body.style, bodyStyle);

  return (
    <div style={{ width: "100%", marginTop: "5vh" }}>
      <Box width={"100%"}>
        <WhatsApp />
        <Box
          style={{ fontSize: "1.9rem", color: "#01B4EC", margin: "0px auto" }}
          display={"flex"}
          flexDirection={"column"}
          width={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
          position={"relative"}
        >
          <Img
            width={["94%", "90%", "90%", "80%"]}
            height={["24vh", "30vh", "40vh", "40vh"]}
            style={{ fontWeight: "600", borderRadius: "20px" }}
            src={collegeData.image1}
            alt="Image 1"
          />
          <Box
            style={{ backgroundColor: "white", borderRadius: "10px" }}
            border={"1px solid #00008B"}
            alignContent={"start"}
            justifyContent={"center"}
            position={"absolute"}
            bottom={["-1vh", "-1vh", "-2vh", "-3vh"]}
            left={["2vw", "2vw", "14vw", "14vw"]}
            width={["24vw", "3vw", "20vw", "13vw"]}
            height={["8vh", "20vh", "15vh", "20vh"]}
          >
            <Img
              style={{ fontWeight: "600", margin: "auto" }}
              height={"100%"}
              src={collegeData.image2}
              alt="Image 2"
            />
          </Box>

          <Box style={{ textAlign: "center", color: "#F4903A" }}>
            <Text
              style={{ fontWeight: "600", marginLeft: "30vw", width: "60vw" }}
              fontSize={{ base: "0.9rem", md: "1.6rem" }}
            >
              {collegeData.collegename}
            </Text>

            <Flex
              // border={"1px solid red"}
              style={{
                color: "black",
                fontSize: "1.2rem",
                justifyContent: "center",
              }}
              gap={2}
            >
              <Icon style={{ marginLeft: "21vw" }} as={FaMapMarkerAlt} />
              <Text
                style={{ textAlign: "center" }}
                fontSize={{ base: "0.8rem", lg: "1rem" }}
              >
                {collegeData.city}, {collegeData.state}
              </Text>
            </Flex>
          </Box>
        </Box>

        <Box
          style={{
            // fontSize: "1rem",
            textAlign: "start",
            marginTop: "6vh",
            background: "#ECECEC",
            width: "100%",
          }}
        >
          <Box>
            <Stack
              direction={["column", "column", "row", "row"]}
              justifyContent={"space-around"}
            >
              <Box
                width={{base:"90%",md:"80%",lg:"24%",xl:"20%"}}
                marginTop={["2vh", "2vh", "10vh", "10vh"]}
                margin={"0px auto"}
                height={["12vh", "12vh", "12vh", "15vh"]}
                style={{
                  backgroundColor: "white",
                  border: "1px solid #F4903A",
                  borderRadius: "10px",
                  borderBottom: "5px solid #F4903A",
                }}
                fontSize={"1rem"}
              >
                <Flex
                  justifyContent={[
                    "space-around",
                    "space-between",
                    "center",
                    "center",
                  ]}
                  px={4}
                  gap={6}
                  style={{ alignItems: "center", height: "100%" }}
                >
                  <Box width={"20%"}>
                    <img style={{ marginRight: "2vw" }} src={img1}></img>
                  </Box>
                  <Box width={"40%"}>
                    <Text
                      fontWeight={"bold"}
                      fontSize={{ base: "0.8rem", lg: "0.8rem",xl:"1.2rem" }}
                    >
                      Established
                    </Text>
                    <Text fontSize={{ base: "0.7rem", lg: "0.8rem",xl:"0.9rem" }}>
                      {collegeData.established}
                    </Text>
                  </Box>
                </Flex>
              </Box>

              <Box
                width={{base:"90%",md:"80%",lg:"24%",xl:"20%"}}
                marginTop={["2vh", "2vh", "10vh", "10vh"]}
                margin={"0px auto"}
                height={["12vh", "12vh", "12vh", "15vh"]}
                style={{
                  backgroundColor: "white",
                  border: "1px solid #F4903A",
                  borderRadius: "10px",
                  borderBottom: "5px solid #F4903A",
                }}
                fontSize={"1rem"}
              >
                <Flex
                  justifyContent={[
                    "space-around",
                    "space-between",
                    "center",
                    "center",
                  ]}
                  px={4}
                  gap={6}
                  style={{ alignItems: "center", height: "100%" }}
                >
                  <Box width={"20%"}>
                    <img style={{ marginRight: "2vw" }} src={img2}></img>
                  </Box>
                  <Box width={"40%"}>
                    <Text
                      fontWeight={"bold"}
                      fontSize={{ base: "0.8rem", lg: "0.8rem",xl:"1.2rem" }}
                    >
                      College Type
                    </Text>
                    <Text fontSize={{ base: "0.7rem", lg: "0.8rem",xl:"0.9rem" }}>
                      {collegeData.collegetype}
                    </Text>
                  </Box>
                </Flex>
              </Box>

              <Box
                width={{base:"90%",md:"80%",lg:"24%",xl:"20%"}}
                marginTop={["2vh", "2vh", "10vh", "10vh"]}
                margin={"0px auto"}
                height={["12vh", "12vh", "12vh", "15vh"]}
                style={{
                  backgroundColor: "white",
                  border: "1px solid #F4903A",
                  borderRadius: "10px",
                  borderBottom: "5px solid #F4903A",
                }}
                fontSize={"1rem"}
              >
                <Flex
                  justifyContent={[
                    "space-around",
                    "space-between",
                    "center",
                    "center",
                  ]}
                  px={4}
                  gap={6}
                  style={{ alignItems: "center", height: "100%" }}
                >
                  <Box width={"20%"}>
                    <img style={{ marginRight: "2vw" }} src={img3}></img>
                  </Box>
                  <Box width={"40%"}>
                    <Text
                      fontWeight={"bold"}
                      fontSize={{ base: "0.8rem", lg: "0.8rem",xl:"1.2rem" }}
                    >
                      Location
                    </Text>
                    <Text fontSize={{ base: "0.7rem", lg: "0.8rem",xl:"0.9rem" }}>
                      {collegeData.city}
                    </Text>
                  </Box>
                </Flex>
              </Box>

              <Box
                width={{base:"90%",md:"80%",lg:"24%",xl:"20%"}}
                marginTop={["2vh", "2vh", "10vh", "10vh"]}
                margin={"0px auto"}
                // height={["12vh", "12vh", "12vh", "15vh"]}
                style={{
                  backgroundColor: "white",
                  border: "1px solid #F4903A",
                  borderRadius: "10px",
                  borderBottom: "5px solid #F4903A",
                }}
                fontSize={"1rem"}
              >
                <Flex
                  justifyContent={[
                    "space-around",
                    "space-between",
                    "center",
                    "center",
                  ]}
                  px={4}
                  gap={6}
                  style={{ alignItems: "center", height: "100%" }}
                >
                  <Box width={"20%"}>
                    <img style={{ marginRight: "2vw" }} src={img4}></img>
                  </Box>
                  <Box width={"40%"}>
                    <Text
                      fontWeight={"bold"}
                      fontSize={{ base: "0.8rem", lg: "0.8rem",xl:"1.2rem" }}
                    >
                      Approval
                    </Text>
                    <Text fontSize={{ base: "0.7rem", lg: "0.8rem",xl:"0.9rem" }}>
                      {collegeData.approval}
                    </Text>
                  </Box>
                </Flex>
              </Box>
            </Stack>
          </Box>
          <Box width={["95vw", "90vw", "80vw", "80vw"]} margin={"0 auto"}>
            <Flex>
              <Box width={["95vw", "90vw", "95vw", "57vw"]}>
                <Box
                  fontSize={"1rem"}
                  style={{
                    backgroundColor: "white",
                    marginTop: "6vh",
                    fontWeight: "40",
                    padding: "4vw",
                    boxShadow:
                      "10px 14px 16px rgba(1, 75, 236, 0.1), 10px 11px 13px rgba(1, 75, 236, 0.1)",
                    borderWidth: "1px",
                    border: "2px solid #1C375B",
                    borderRadius: "35px",
                  }}
                >
                  <Text
                    textAlign={"center"}
                    fontWeight={"600"}
                    fontSize={{ base: "0.8rem", lg: "1rem" }}
                  >
                    {collegeData.collegename}
                  </Text>
                  <Text
                    fontSize={{ base: "0.8rem", lg: "1rem" }}
                    style={{
                      color: "#333",
                      marginTop: "3vh",
                      fontFamily: "Roboto, sans-serif",
                      fontWeight: "100",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: collegeData.universityDescription,
                    }}
                  ></Text>
                  <Table mt={4}>
                    <Thead>
                      <Tr bgColor="#01B4EC">
                        <Th
                          textAlign={"center"}
                          fontSize={{ base: "0.8rem", lg: "1rem" }}
                          color="black"
                        >
                          Particulars
                        </Th>
                        <Th
                          textAlign={"center"}
                          fontSize={{ base: "0.8rem", lg: "1rem" }}
                          color="black"
                        >
                          Details
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td
                          textAlign={"center"}
                          fontWeight="bold"
                          fontSize={{ base: "0.8rem", lg: "1rem" }}
                        >
                          Address
                        </Td>
                        <Td
                          textAlign={"center"}
                          fontSize={{ base: "0.8rem", lg: "1rem" }}
                        >
                          {collegeData.city}, {collegeData.state}
                        </Td>
                      </Tr>
                      {/* Uncomment if you want to include these rows */}
                      {/* <Tr>
          <Td fontWeight="bold">State</Td>
          <Td>{collegeData.state}</Td>
        </Tr>
        <Tr bgColor="gray.100">
          <Td fontWeight="bold">City</Td>
          <Td>{collegeData.city}</Td>
        </Tr> */}
                      <Tr bgColor="gray.100">
                        <Td
                          textAlign={"center"}
                          fontWeight="bold"
                          fontSize={{ base: "0.8rem", lg: "1rem" }}
                        >
                          Affiliated By
                        </Td>
                        <Td
                          textAlign={"center"}
                          fontSize={{ base: "0.8rem", lg: "1rem" }}
                        >
                          {collegeData.affiliatedby}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td
                          textAlign={"center"}
                          fontWeight="bold"
                          fontSize={{ base: "0.8rem", lg: "1rem" }}
                        >
                          College Category
                        </Td>
                        <Td
                          textAlign={"center"}
                          fontSize={{ base: "0.8rem", lg: "1rem" }}
                        >
                          {collegeData.collegecategory}
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Box>

              <StickyContainer>
                <Sticky
                  topOffset={-200}
                  bottomOffset={200}
                  stickyStyle={{ zIndex: 1 }}
                >
                  {({ style }) => (
                    <Box
                      display={["none", "none", "none", "block"]}
                      style={{
                        ...style,
                        marginLeft: "5vw",
                        marginTop: "20vh",
                        background: "rgba(255, 0, 0, 0.7)",
                        width: "25vw",
                        position: "fixed",
                        backgroundColor: "white",
                        boxShadow:
                          "10px 14px 16px rgba(1, 75, 236, 0.1), 10px 11px 13px rgba(1, 75, 236, 0.1)",
                        borderWidth: "1px",
                        border: "2px solid #1C375B",
                        borderRadius: "35px",
                      }}
                    >
                      <VStack width={"25vw"} py={6}>
                        <Text
                          style={{
                            fontsize: "1rem",
                            fontWeight: "600",
                          }}
                        >
                          Contact Details
                        </Text>

                        <Box style={{ fontSize: "1rem" }}>
                          <Flex
                            style={{
                              color: "black",
                              marginTop: "2vh",
                              justifyContent: "center",
                            }}
                            gap={2}
                          >
                            <Icon
                              style={{ fontWeight: "100" }}
                              as={FaMapMarkerAlt}
                            />
                            <Text
                              style={{ fontWeight: "600", textAlign: "start" }}
                            >
                              Address
                            </Text>
                          </Flex>
                          <Text
                            style={{ textAlign: "center", color: "#014BEC" }}
                          >
                            {collegeData.city}, {collegeData.state}
                          </Text>
                        </Box>

                        {/* <Box
                          style={{
                            fontSize: "1rem",
                            width: "100%",
                            overflow: "hidden",
                          }}>
                          <Flex
                            style={{
                              color: "black",
                              marginTop: "5vh",
                              justifyContent: "center",
                            }}
                            gap={2}>
                            <FaGlobe style={{ fontWeight: "100" }} />
                            <Text
                              style={{ fontWeight: "600", textAlign: "start" }}>
                              {" "}
                              website
                            </Text>
                          </Flex>
                          <Box
                            width={"20vw"}
                            style={{
                              marginLeft: "2vw",
                              overflow: "hidden",
                              wordWrap: "break-word",
                              textAlign: "center",
                            }}>
                            <Text
                              color={"red"}
                              style={{ color: "#014BEC", fontSize: "1rem" }}>
                              <a
                                href={`${
                                  collegeData.website.startsWith("http://") ||
                                  collegeData.website.startsWith("https://")
                                    ? collegeData.website
                                    : "http://" + collegeData.website
                                }`}
                                target="_blank">
                                {collegeData.website}
                              </a>
                            </Text>
                          </Box>
                        </Box> */}
                      </VStack>
                    </Box>
                  )}
                </Sticky>
              </StickyContainer>

              <Box
                style={{
                  position: "absolute",
                  right: "28vw",
                  bottom: "80vh",
                }}
              ></Box>
            </Flex>
          </Box>
        </Box>
        <Box
          // border={"1px solid blue"}
          style={{ color: "#01B4EC", margin: "0px auto" }}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Text
            fontSize={{ base: "1rem", lg: "1.4rem" }}
            style={{ color: "#1C375B", fontWeight: "600", marginTop: "5vh" }}
          >
            For more details and Cutoffs
          </Text>
          <Button
            onClick={() => {
              window.location.href = "https://api.mbbsdunia.com/pay";
              // window.location.href = "http://localhost:3100/pay";
            }}
            marginTop={"2vh"}
            marginBottom={"4vh"}
            background={"#1C375B"}
            color={"white"}
            fontWeight={"500"}
            borderRadius={"full"}
            fontsize={"1.6rem"}
            width={{ base: "50%", lg: "12%" }}
            _hover={{
              color: "black",
              border: "2px solid #1C375B",
              background: "none",
            }}
          >
            Get Cutoffs
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default Collegepage;
